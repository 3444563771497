<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-input type="text" placeholder="请输入标题" v-model="query.title"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.add" @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'messageId'" v-loading="showLoading"
                :height="tableHeight">
                <el-table-column prop="messageId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <!-- 消息类型（-1:单点登录信息 0 系统消息；1:诊疗日志；2:积分订单发货；3:动态评论；4:动态点赞；5:评论点赞；6:被关注） -->
                <el-table-column prop="targetType" label="消息类型" min-width="80" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.targetType == -1">单点登录信息</span>
                        <span v-if="scope.row.targetType == 0">系统消息</span>
                        <span v-if="scope.row.targetType == 1">诊疗日志</span>
                        <span v-else-if="scope.row.targetType == 2">积分订单发货</span>
                        <span v-else-if="scope.row.targetType == 3">动态评论</span>
                        <span v-else-if="scope.row.targetType == 4">动态点赞</span>
                        <span v-else-if="scope.row.targetType == 5">评论点赞</span>
                        <span v-else-if="scope.row.targetType == 6">被关注</span>
                        <span v-else></span>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="标题" min-width="120"></el-table-column>
                <el-table-column prop="content" label="内容" align="center" width="80">
                    <template slot-scope="scope">
                        <el-link type="primary" @click="hoverContent(scope.row)">详情</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="addTime" label="添加时间" align="center" width="140">
                    <template slot-scope="scope">
                        {{ scope.row.addTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="130px" align="center" >
                    <template slot-scope="scope">
                        <!-- <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                            <el-button
                                class="success"
                                icon="el-icon-edit-outline"
                                type="text"
                                v-if="permission.edit"
                                @click="clickEditInfo(scope.row)"
                            ></el-button>
                        </el-tooltip> -->

                        <el-tooltip class="item" effect="dark" content="删除" placement="top">
                            <el-popconfirm
                                title="您确定要删除么？"
                                icon-color="red"
                                v-if="permission.remove"
                                @confirm="deleteAdmin(scope.row, scope.$index)"
                            >
                                <el-button
                                slot="reference"
                                icon="el-icon-delete"
                                type="text"
                                class="danger"
                                >
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.page" :page-sizes="[10, 30, 50, 100]" :page-size="query.page_size"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看消息详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

        <!-- 添加消息 -->
        <el-dialog :title="`${editForm.id ? '编辑' : '添加'}消息`" :visible.sync="addOrEditDialog" :modal="false"
            :destroy-on-close="true" fullscreen @close="resetAddOrEditData">
            <el-form label-width="120px" ref="editForm" :model="editForm" :rules="addRules">
                <el-form-item label="消息标题" prop="title">
                    <el-input size="medium" v-model="editForm.title" placeholder="请输入消息标题"></el-input>
                </el-form-item>
                <el-form-item label="消息内容" prop="content">
                    <Editor v-if="addOrEditDialog" v-model="editForm.content"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    getList
} from '@/tools/getList.js'
import systemSetManager from "@/request/systemSetManager.js";
import ShowContent from "@/components/common/subgroup/ShowContent.vue"
import Editor from "@/components/common/subgroup/Editor.vue"; //编辑器
export default {
    mixins: [getList],
    components: {
        ShowContent,
        Editor
    },
    data() {
        return {
            requst: {
                module: systemSetManager,
                url: 'messageList',
                addUrl: 'messageSave',
                updateUrl: 'messageUpdate'
            },
            dialogSeeContent: false,
            rowInfo: {},
            query: {
                title: '',    //搜索词
            },
            addOrEditDialog: false,
            addOrEditBtnLoading: false,
            editForm: {
                id: 0,
                title: "",
                content: "",
            },

            addRules: {
                title: [{ required: true, message: "请输入消息标题", trigger: "blur" }],
                content: [{ required: true, message: "请输入消息内容", trigger: "blur" }],
            },
        };
    },
    created() {

    },
    methods: {
        hoverContent(row) {
            this.rowInfo = row;
            this.dialogSeeContent = true;
        },

        // 点击添加
        onAdd() {
            this.addOrEditDialog = true
        },

        // 点击添加---取消
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        // 点击添加---确定
        clickAddOrEditConfirm() {
            this.$refs.editForm.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        let dataValue = JSON.parse(JSON.stringify(this.editForm))
                        let data = await this.requst.module[this.editForm.id > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
                        if (data.code == 200) {
                            this.$message({
                                message: data.message,
                                type: 'success'
                            });
                            this.$emit('success')
                            this.refreshData() 
                            this.resetAddOrEditData()
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }

                }
            })
        },

        // 清除添加数据
        resetAddOrEditData() {
            this.addOrEditDialog = false
            this.editForm = {
                id: 0,
                title: "",
                content: "",
            }
        },
        // 点击编辑信息
        async clickEditInfo(row) {
            var content;
            if (row.content.indexOf("<p>") == -1) {
                content = '<p>' +  row.content + '</p>';
            }else{
                content = row.content;
            }
            this.editForm = {
                id: row.id,
                title: row.title,
                content: content
            }
            this.addOrEditDialog = true
        },

        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await systemSetManager.messageRemove({ messageId: row.messageId });
                if (code === 200) {
                    this.$message.success("删除成功");
                    this.delRefresh(index);
                }
            } catch (error) {
                console.log(error)
            }
        },
    },
};
</script>

<style scoped>
</style>
